var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.forMenu)?_c('v-btn',_vm._g(_vm._b({staticClass:"vcad-btn",attrs:{"small":"","outlined":"","color":"primary","elevation":"2","loading":_vm.downloadProcessStatus,"disabled":_vm.filesItem.length<=0},on:{"click":_vm.downloadFile}},'v-btn',attrs,false),on),[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-file-download")])]}}],null,true)},[_c('span',[_vm._v("Downlaod source file")])])],1):_c('v-btn',_vm._g({staticClass:"vcad-btn",attrs:{"outlined":"","small":"","loading":_vm.downloadProcessStatus,"color":"primary"},on:{"click":_vm.downloadFile}},on),[_vm._v("Download")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"dense":""}},[_c('v-card-title',{staticClass:"text-h6"},[_vm._v(" Download files: "+_vm._s(_vm.filesItem.length ? _vm.filesItem.length : 1)+" ")]),_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.filesProcessed),function(item,i){return _c('v-list-item',{key:i,attrs:{"dense":""}},[_c('v-list-item-icon',{attrs:{"dense":""}},[(item.start)?_c('div',[(!item.check)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"red"}},on),[_vm._v("mdi-alert")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.error))])]):_c('v-icon',{attrs:{"small":"","color":"green"}},[_vm._v("mdi-check")])],1):_c('v-progress-circular',{attrs:{"indeterminate":"","size":20,"color":"primary"}})],1),_c('v-list-item-content',{attrs:{"dense":""}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)}),1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"vcad-btn",attrs:{"outlined":"","small":"","color":"","text":"","disabled":_vm.downloadProcessStatus},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" close ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }